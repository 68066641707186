import p1 from "../assets/productAssets/p1.png";
import p2 from "../assets/productAssets/p2.jpg";
import p3 from "../assets/productAssets/p3.jpg";
import p4 from "../assets/productAssets/p4.webp";
import p5 from "../assets/productAssets/p5.jpg";
import p6 from "../assets/productAssets/p6.jpg";
import p7 from "../assets/productAssets/p7.jpg";
import p8 from "../assets/productAssets/p8.webp";
import p9 from "../assets/productAssets/p9.webp";
import p10 from "../assets/productAssets/p10.webp";
import p11 from "../assets/productAssets/p11.webp";
import p12 from "../assets/productAssets/p12.jpg";
import p13 from "../assets/productAssets/p13.png";
import p14 from "../assets/productAssets/p14.jpg";
import p15 from "../assets/productAssets/p15.png";
import p16 from "../assets/productAssets/p16.avif";
import p17 from "../assets/productAssets/p17.jpg";
import p18 from "../assets/productAssets/p18.webp";
import p19 from "../assets/productAssets/p19.webp";
import p20 from "../assets/productAssets/p20.webp";
import p21 from "../assets/productAssets/p21.jpg";
import p22 from "../assets/productAssets/p22.webp";
import p23 from "../assets/productAssets/p23.jpg";
import p24 from "../assets/productAssets/p24.jpg";
import p25 from "../assets/productAssets/p25.jpg";
import p26 from "../assets/productAssets/p26.webp";
import p27 from "../assets/productAssets/p27.webp";
import p28 from "../assets/productAssets/p28.jpg";
import p29 from "../assets/productAssets/p29.jpeg";
import p30 from "../assets/productAssets/p30.webp";
import p31 from "../assets/productAssets/p31.webp";
import p32 from "../assets/productAssets/p32.jpg";
import p33 from "../assets/productAssets/p33.jpg";
import p34 from "../assets/productAssets/p34.webp";
import p35 from "../assets/productAssets/p35.webp";
import p36 from "../assets/productAssets/p36.webp";
import p37 from "../assets/productAssets/p37.png";
import p38 from "../assets/productAssets/p38.png";
import p39 from "../assets/productAssets/P39.avif";
import p40 from "../assets/productAssets/p40.jpg";
import p41 from "../assets/productAssets/p41.webp";
import p42 from "../assets/productAssets/p42.webp";
import p43 from "../assets/productAssets/p43.webp";
import p44 from "../assets/productAssets/p44.webp";
import p45 from "../assets/productAssets/p45.webp";
import p46 from "../assets/productAssets/p46.jpg";
import p47 from "../assets/productAssets/p47.jpeg";
import p48 from "../assets/productAssets/p48.webp";
import p49 from "../assets/productAssets/p49.jpg";
import p50 from "../assets/productAssets/p50.webp";
import p51 from "../assets/productAssets/p51.webp";
import p52 from "../assets/productAssets/p52.webp";
import p53 from "../assets/productAssets/p53.jpg";
import p54 from "../assets/productAssets/p54.webp";
import p55 from "../assets/productAssets/p55.jpeg";
import p56 from "../assets/productAssets/p56.jpeg";
import p57 from "../assets/productAssets/p57.webp";
import p58 from "../assets/productAssets/orto.webp";
import p59 from "../assets/productAssets/p59.webp";

import { v4 as uuidv4 } from "uuid";

const productsData = [
  {
    id: 158,
    image: p59,
    brand: "YSL",
    productName: "MYSLF Le Parfum",
    link: "https://www.instagram.com/p/C0_mxvJNxhi/?img_index=1",
    price: 144.99,
    // discountPrice: 109.99,
    size: 100,
    seasons: ["Spring", "Summer"],
    ingredients: ["Citrus", "White Floral", "Patchouli", "Woody"],
    description:
      "Highly sensual, intensily rich. MYSLF Le Parfum, the new intensity born from all modern men contrasts. The quintessence of a man born to be nothing but himself. Radically bold. Essentially contrasted. Unapologetically nuanced.",
  },
  {
    id: 2,
    brand: "Emporio Armani",
    image: p2,
    productName: "Stronger With You Intensely",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 104.99,
    size: 100,
    seasons: ["Fall", "Winter"],
    ingredients: ["Citrus", "White Floral", "Patchouli", "Woody"],
    description:
      "Armani Stronger With You Intensely is a bold fragrance with sweet vanilla and warm spices. It feels like a cozy hug, perfect for confident individuals who enjoy a touch of sweetness.",
  },
  {
    id: 3,
    brand: "Jean Paul Gaultier",
    image: p56,
    productName: "Le Beau",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 99.99,
    seasons: ["Spring", "Summer"],
    size: 125,
    description:
      "Le Beau by Jean Paul Gaultier is a Woody Aromatic fragrance for men. Le Beau was launched in 2019. Le Beau was created by Quentin Bisch and Sonia Constant. Top note is Bergamot; middle note is Coconut; base note is Tonka Bean.",
  },
  {
    id: 4,
    brand: "Jean Paul Gaultier",
    image: p57,
    productName: "Paradise Garden",
    link: "https://www.instagram.com/p/C2xC54Ito6n/",
    price: 129.99,
    seasons: ["Spring", "Summer"],
    size: 125,
    description:
      "A woody, green aquatic fragrance by Master Perfumer Quentin Bisch. This heavenly fragrance is a ray of tropical sunshine with its dazzling flowers and seductive scents, all plucked from the Garden of Gaultier. Fresh, salty coconut melds with green fig and sensual sandalwood.",
  },
  {
    id: 58,
    brand: "Orto Parisi",
    image: p58,
    productName: "Megamare",
    link: "https://www.instagram.com/p/C2xC54Ito6n/",
    price: 124.99,
    seasons: ["Spring", "Summer"],
    size: 50,
    description:
      "Megamare is a swirling typhoon of a scent, a heady wash of brackish water, biting green algae, spicy-sweet sea air, and a sparkling musk that radiates with the intensity of the midday sun reflecting off the rolling waves of the open ocean. With immense longevity and powerful projection.",
  },

  {
    id: 5,
    brand: "Jean Paul Gaultier",
    image: p3,
    productName: "SCANDAL Pour Homme",
    link: "https://www.instagram.com/p/Cst_K0qqwq0/",
    price: 149.99,
    size: 100,
    seasons: ["Fall", "Winter"],
    discountPrice: 119.99,
    description:
      "SCANDAL Pour Homme is a captivating fragrance with fresh citrus, spicy black pepper, and a touch of tonka bean sweetness. It embodies a modern, charismatic scent for the confident man.",
  },
  {
    id: 1,
    image: p1,
    brand: "YSL",
    productName: "MYSLF",
    link: "https://www.instagram.com/p/C0_mxvJNxhi/?img_index=1",
    price: 139.99,
    discountPrice: 109.99,
    size: 100,
    seasons: ["Spring", "Summer"],
    ingredients: ["Citrus", "White Floral", "Patchouli", "Woody"],
    description:
      "YSL MYSLF Eau de Parfum is a friendly mix of fruity blackcurrant, zesty bergamot, and cozy white musk. Its flowery heart brings a touch of modern charm, creating a fragrance that feels inviting and down-to-earth.",
  },
  {
    brand: "Giorgio Armani",
    image: p4,
    productName: "Armani Code",
    link: "https://www.instagram.com/p/CrazFE6oGqe/",
    price: 119.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Iris", "Powdery", "Woody"],
    description:
      "Armani Code is a timeless fragrance featuring a blend of citrus, aromatic herbs, and a hint of wood. It exudes sophistication, making it a classic choice for the modern gentleman.",
  },
  {
    brand: "Montale",
    image: p48,
    productName: "Arabians Tonka",
    link: "https://www.instagram.com/p/Cso14FNt-ld/?img_index=1",
    price: 124.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Warm Spicy", "Woody", "Patchouli"],
    description:
      "Montale Arabians Tonka is an opulent and oriental fragrance with notes of tonka bean, vanilla, and tobacco. It offers a rich and warm scent profile, exuding a luxurious and captivating essence.",
  },
  {
    brand: "Giorgio Armani",
    image: p44,
    productName: "My Way",
    link: "https://www.instagram.com/p/Cq32VLqtOCO/",
    price: 129.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    discountPrice: 109.99,
    description:
      "Armani My Way is a contemporary and empowering fragrance, featuring a harmonious blend of white florals, cedarwood, and vanilla. It embodies a journey of self-discovery with a modern and elegant allure, making it a signature scent for confident individuals.",
  },
  {
    brand: "Valentino",
    image: p5,
    productName: "Uomo Born In Roma",
    link: "https://www.instagram.com/p/CouGBVYq_6o/",
    price: 119.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Valentino Uomo Born In Roma is a charismatic scent blending warm vanilla, spicy ginger, and aromatic sage. It embodies a modern, confident allure, perfect for those who appreciate a unique sophistication.",
  },
  {
    brand: "Louis Vuitton",
    image: p47,
    productName: "Ombre Nomade",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 329.99,
    discountPrice: 279.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Louis Vuitton Ombre Nomade is a luxurious fragrance inspired by the Middle East. With rich oud, benzoin, and raspberry notes, it embodies warmth and sophistication, creating a captivating and evocative scent.",
  },
  {
    brand: "Tom Ford",
    image: p6,
    productName: "Ombré Leather Fragrance",
    link: "https://www.instagram.com/p/Czt15-jNJs_/",
    price: 179.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Warm Spicy", "Woody", "Patchouli"],
    description:
      "Tom Ford Ombré Leather is a luxurious fragrance, blending sensual leather, spicy cardamom, and floral jasmine. It exudes a sophisticated and captivating aura, making it an ideal choice for those who appreciate refinement.",
  },
  {
    brand: "Dolce&Gabbana ",
    image: p7,
    productName: "The One EDP",
    link: "https://www.instagram.com/p/CzmRhm_tgkn/",
    price: 104.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Dolce&Gabbana The One Eau de Parfum is a timeless scent with notes of fruity peach, floral jasmine, and warm vanilla. It radiates elegance, capturing the essence of modern glamour.",
  },
  {
    brand: "Prada",
    image: p11,
    productName: "Paradoxe",
    link: "https://www.instagram.com/p/CuUsAAtNkxg/",
    price: 134.99,
    discountPrice: 116.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "This is a floral, ambery perfume that reinvents freshness with a neroli bud extraction to capture the flower’s fresh scent, sensuality with bio-converted amber to reveal a vibrant warmth, and intensity through a revolutionary musk molecule for an intense yet subtle trail.",
  },
  {
    brand: "Tom Ford",
    image: p51,
    productName: "Bitter Peach 100ml",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 264.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Warm Spicy", "Woody", "Patchouli"],
    description:
      "Tom Ford Bitter Peach is a bold and seductive fragrance with notes of ripe peach, blood orange, and patchouli. It exudes a provocative and luxurious aura, capturing the essence of indulgence.",
  },
  {
    brand: "Tom Ford",
    image: p50,
    productName: "Bitter Peach 50ml",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 189.99,
    size: 50,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Warm Spicy", "Woody", "Patchouli"],
    description:
      "Tom Ford Bitter Peach is a bold and seductive fragrance with notes of ripe peach, blood orange, and patchouli. It exudes a provocative and luxurious aura, capturing the essence of indulgence.",
  },
  {
    brand: "YSL",
    image: p8,
    productName: "Y Edp Intense",
    link: "https://www.instagram.com/p/CzlSZw2NaS4/",
    price: 109.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Y Eau de Parfum Intense by Yves Saint Laurent is a bold fragrance with a fusion of bergamot, spicy ginger, and aromatic sage. It embodies strength and sophistication for the modern man.",
  },
  {
    brand: "Tom Ford",
    image: p9,
    productName: "Noir Extreme",
    link: "https://www.instagram.com/p/CylZltntulz/",
    price: 169.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Tom Ford Noir Extreme is a decadent fragrance blending warm amber, spicy cardamom, and sweet vanilla. It's an opulent scent that exudes sophistication and allure for those seeking a distinctive presence.",
  },
  {
    brand: "Carolina Herrera",
    image: p10,
    productName: "212 VIP Black",
    link: "https://www.instagram.com/p/CyYjlUkNdj9/",
    price: 89.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Carolina Herrera 212 VIP Black is a bold and seductive fragrance, featuring a blend of exotic spices, fruity caviar, and woody notes. It embodies a confident and mysterious allure.",
  },
  {
    brand: "Xerjoff",
    image: p54,
    productName: "Erba Pura",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 244.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Erba Pura is an enticing exilir that captivates and charms. Fresh, fruity and thoroughly contemporary in spirit, it belnds top notes of Sicilan orange and lemon with a Mediterrenean fruit basket at the heart.",
  },
  {
    brand: "Montale",
    image: p42,
    productName: "Pure Love",
    link: "https://www.instagram.com/p/Cso14FNt-ld/?img_index=1",
    price: 124.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    ingredients: ["Main Accords", "Warm Spicy", "Woody", "Patchouli"],
    description:
      "Pure Love is a luminous fragrance tenderly woody, spicy and leathery. The powerful scent of Rose blends effortlessly with the sophisticated scent of Sandalwood and Vetiver, and everything softened by a subtle touch of Ambergris, Vanilla and Leather.",
  },
  {
    brand: "Parfums de Marly",
    image: p52,
    productName: "Layton",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 329.99,
    size: 125,
    seasons: ["Summer", "Fall"],
    description:
      "Parfums de Marly Layton is a refined and captivating fragrance. With notes of apple, lavender, and vanilla, it offers a modern and elegant scent, embodying a sophisticated and confident presence.",
  },
  {
    brand: "Parfums de Marly",
    image: p53,
    productName: "Herod Eau de Parfum",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 329.99,
    size: 125,
    description:
      "Parfums de Marly Herod Eau de Parfum is a distinguished and opulent fragrance. Combining notes of tobacco, vanilla, and cinnamon, it offers a rich, warm, and regal scent for a bold presence.",
  },
  {
    brand: "Giorgio Armani",
    image: p12,
    productName: "Si Passione",
    link: "https://www.instagram.com/p/CwXhj96NaSK/",
    price: 164.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Giorgio Armani Si Passione is a passionate and feminine fragrance with notes of red fruits, floral accents, and vanilla. It exudes confidence and sophistication, making it a captivating choice for modern women.",
  },
  {
    brand: "Valentino",
    image: p13,
    productName: "Uomo Born In Roma Yellow Dream",
    link: "https://www.instagram.com/p/CvhZETlofgl/",
    price: 109.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "A spicy, ambery cologne that entices the senses with bursts of fresh lavender and spicy cinnamon balanced by a base of warm, rich vanilla.",
  },
  {
    brand: "Burberry",
    image: p45,
    productName: "Goddess",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 99.99,
    discountPrice: 84.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Burberry Goddess tells a story of self-discovery. The new campaign follows Emma Mackey and a pride of lionesses. Radiating confidence, strength and kindness, Emma embodies the spirit of Burberry Goddess Eau de Parfum.",
  },
  {
    brand: "Dior",
    image: p14,
    productName: "Homme Intense",
    link: "https://www.instagram.com/p/CvScHIMNMYv/",
    price: 126.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    discountPrice: 104.99,
    description:
      "A fragrance overdosed with carnal woody notes, and it represents a return to the basics: classic and simplicity. It is clear, clean, gentle, and woody but also slightly sweet.",
  },
  {
    brand: "Carolina Herrera",
    image: p15,
    productName: "Bad Boy Cobalt",
    link: "https://www.instagram.com/p/CvIZRGMtYZu/",
    price: 114.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Carolina Herrera Bad Boy Cobalt is a captivating fragrance that blends invigorating citrus with spicy black pepper and warm tonka bean. It exudes a confident and dynamic aura for the modern man.",
  },
  {
    brand: "Dior",
    image: p25,
    productName: "Sauvage EDT",
    link: "https://www.instagram.com/p/Cq09KGxINiy/",
    price: 94.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Dior Sauvage Eau de Toilette is a magnetic and fresh fragrance with notes of bergamot, spicy Sichuan pepper, and warm ambroxan. It exudes a rugged and modern allure, making it a versatile choice for men.",
  },
  {
    brand: "Chanel",
    image: p16,
    productName: "Bleu de Chanel",
    link: "https://www.instagram.com/p/CvFJKA9t6Fm/",
    price: 134.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Bleu de Chanel is a modern and sophisticated fragrance with invigorating citrus, aromatic woods, and a hint of incense. It embodies a timeless elegance, making it a versatile choice for various occasions.",
  },
  {
    brand: "Dolce&Gabbana",
    image: p17,
    productName: "Light Blue pour Homme",
    link: "https://www.instagram.com/p/Cu4GetaocXY/",
    price: 74.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Dolce & Gabbana Light Blue pour Homme is a refreshing fragrance that captures the essence of the Mediterranean. With citrusy notes and a blend of woods, it exudes a casual and vibrant sophistication.",
  },
  {
    brand: "Dolce&Gabbana",
    image: p46,
    productName: "My Only One",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 149.99,
    discountPrice: 129.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Dolce & Gabbana My Only One is a captivating fragrance that weaves together notes of orange blossom, vanilla, and violet. It creates a warm and sweet olfactory journey, embodying a sense of uniqueness and allure.      ",
  },
  {
    brand: "Tom Ford",
    image: p49,
    productName: "Tobacco Vanille",
    link: "https://www.instagram.com/coolest.parfume/",
    price: 169.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Tom Ford Tobacco Vanille is a rich and decadent fragrance with a blend of tobacco leaf, vanilla, and spices. It creates a warm and opulent scent, embodying a luxurious and sophisticated indulgence.",
  },
  {
    brand: "Giorgio Armani",
    image: p18,
    productName: "Acqua di Giò Porfumo",
    link: "https://www.instagram.com/p/CuXOUJSohYl/",
    price: 149.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Acqua di Giò by Giorgio Armani is an iconic fragrance that captures the freshness of the sea. With notes of bergamot, marine accord, and woody elements, it embodies a timeless and aquatic sophistication.",
  },
  {
    brand: "Giorgio Armani",
    image: p19,
    productName: "Acqua di Giò Profondo",
    link: "https://www.instagram.com/p/CtvsqiRoaOq/",
    price: 114.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Acqua di Giò Profondo by Giorgio Armani is a captivating fragrance that dives into the depths of the ocean. With marine notes, aromatic herbs, and woody accords, it exudes a fresh and profound sophistication.",
  },
  {
    brand: "Viktor&Rolf",
    image: p32,
    productName: "Spicebomb Extreme",
    link: "https://www.instagram.com/p/CoyBda1IBFe/",
    price: 139.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Spicebomb Extreme by Viktor & Rolf is a bold and intense fragrance, featuring spicy notes of black pepper, vanilla, and tobacco. It exudes a warm and captivating allure, perfect for those seeking a powerful and sophisticated scent.",
  },
  {
    brand: "Paco Rabanne",
    image: p20,
    productName: "Invictus Victory",
    link: "https://www.instagram.com/p/Ctb9JC2KRGK/",
    price: 90.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Paco Rabanne Invictus Victory is a bold and energetic fragrance, featuring a burst of fresh laurel, warm spices, and a powerful woody base. It embodies triumph and vitality for the confident man.",
  },
  {
    brand: "Givenchy",
    image: p21,
    productName: "L’interdit",
    link: "https://www.instagram.com/p/CsM4A_JNJKk/?img_index=2",
    price: 124.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "GIVENCHY L’INTERDIT is a daring and elegant fragrance with white florals, tuberose, and a hint of patchouli. It radiates a forbidden allure, embodying modern femininity with sophistication and mystery.",
  },
  {
    brand: "Burberry",
    image: p22,
    productName: "Hero Eau De Parfume",
    link: "https://www.instagram.com/p/Cr-57jyoBys/",
    price: 133.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Burberry Hero Eau de Parfum is a captivating fragrance that blends invigorating bergamot, spicy black pepper, and warm cedarwood. It embodies a modern, heroic spirit with a sophisticated and masculine allure.",
  },
  {
    brand: "Jean Paul Gaultier",
    image: p23,
    productName: "Le Male",
    link: "https://www.instagram.com/p/Cr58GsCoy7y/",
    price: 94.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Jean Paul Gaultier Le Male is an iconic fragrance with a fresh blend of mint, lavender, and vanilla. It's a classic and bold scent that exudes confidence and masculinity.",
  },
  {
    brand: "Jean Paul Gaultier",
    image: p55,
    productName: "Le Beau de Parfume Intense",
    link: "https://www.instagram.com/p/Cr58GsCoy7y/",
    price: 99.99,
    size: 125,
    description:
      "Jean Paul Gaultier Le Beau Eau de Parfum Intense is a captivating and bold fragrance. With notes like coconut wood, bergamot, and tonka bean, it offers an intense, seductive, and sophisticated aroma.",
  },
  {
    brand: "Coco Channel",
    image: p24,
    productName: "Mademoiselle",
    link: "https://www.instagram.com/p/Cq349KCt_Gv/",
    price: 134.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Coco Chanel Mademoiselle is a timeless and elegant fragrance with notes of citrus, patchouli, and vanilla. It exudes a modern and sophisticated charm, capturing the essence of a confident woman.",
  },
  {
    brand: "Prada",
    image: p26,
    productName: "Luna Rossa BLACK",
    link: "https://www.instagram.com/p/CqvmKc5qjO0/",
    price: 90,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Prada Luna Rossa Black is a modern and intense fragrance featuring notes of bergamot, angelica, and patchouli. It exudes sophistication with a dark and mysterious character, perfect for the confident man.",
  },
  {
    brand: "Dior",
    image: p27,
    productName: "Sauvage Elixir",
    link: "https://www.instagram.com/p/Cqn-Kb9KubT/",
    price: 229.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Dior Sauvage Elixir is a bold and intense fragrance, combining spicy notes of Sichuan pepper with warm and sweet tonka bean. It creates a rich and captivating scent for a confident presence.",
  },
  {
    brand: "Versace",
    image: p28,
    productName: "Eros (EDT)",
    link: "https://www.instagram.com/p/Cp5UIiTInnn/",
    price: 114.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Eros by Versace (Eau de Toilette) is a vibrant and seductive fragrance, blending fresh mint, green apple, and warm vanilla. It exudes a passionate and charismatic aura, making it an alluring choice for men.",
  },
  {
    brand: "Bvlgari",
    image: p29,
    productName: "Man In Black",
    link: "https://www.instagram.com/p/Cpsy_72oqKi/",
    price: 112.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Bvlgari Man In Black is a rich and sensual fragrance featuring spicy notes of rum, tobacco, and leather. It exudes a warm and luxurious essence, capturing the bold and sophisticated spirit.",
  },
  {
    brand: "YSL",
    image: p30,
    productName: "Libre",
    link: "https://www.instagram.com/p/Co8GPQSNHYO/",
    price: 124.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Libre Eau de Parfum is a bold and floral perfume for women. A statement feminine fragrance for those who live by their own rules.",
  },
  {
    brand: "Dadivoff",
    image: p31,
    productName: "Cool Water",
    link: "https://www.instagram.com/p/Co7V89DoTaa/",
    price: 59.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Davidoff Cool Water is a classic and refreshing fragrance with notes of oceanic freshness, lavender, and mint. It embodies a cool and invigorating essence, making it a timeless choice for a clean and crisp scent.",
  },
  {
    brand: "Ralph Lauren",
    image: p33,
    productName: "Polo Black",
    link: "https://www.instagram.com/p/CouIcS-qsLB/",
    price: 89.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Ralph Lauren Polo Black is a modern and versatile fragrance with notes of mango, tonka bean, and patchouli. It exudes a confident and energetic vibe, making it a timeless choice for a sophisticated yet casual scent.",
  },
  {
    brand: "Maison Francis Kurkdijan",
    image: p43,
    productName: "Paris",
    link: "https://www.instagram.com/p/CosxClloSFZ/",
    price: 339.9,
    discountPrice: 289.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Maison Francis Kurkdjian Paris, founded by perfumer Francis Kurkdjian, epitomizes luxury fragrances. Renowned for meticulous craftsmanship, their collection spans diverse scents, each a sophisticated exploration of notes, embodying refined elegance and artistic expression. For specific details on a fragrance, refer to the official website or authorized retailers.",
  },
  {
    brand: "HUGO BOSS",
    image: p34,
    productName: "THE SCENT LA PARFUM",
    link: "https://www.instagram.com/p/CouH8tIo1pS/",
    price: 124.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Hugo Boss The Scent Private Accord is a rich and seductive fragrance. It combines notes of ginger, exotic maninka fruit, and dark cocoa, creating an irresistible and luxurious scent for a confident allure.",
  },
  {
    brand: "Terre d’Hermes",
    image: p35,
    productName: "Hermes",
    link: "https://www.instagram.com/p/CouHOO4opwo/",
    price: 90,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Terre d'Hermès by Hermès is an iconic fragrance with a blend of citrus, pepper, and woody notes. It evokes the essence of earth, expressing a modern, masculine sophistication.",
  },
  {
    brand: "Givenchy",
    image: p36,
    productName: "Gentleman EDP Boisée",
    link: "https://www.instagram.com/p/CouGXvMoQog/",
    price: 79.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Givenchy Gentleman Eau de Parfum Boisée is a modern and refined fragrance. With notes of cedarwood, iris, and vetiver, it delivers a woody and sophisticated essence for a confident and contemporary gentleman.",
  },
  {
    brand: "Paco Rabanne",
    image: p37,
    productName: "1 Million Lucky",
    link: "https://www.instagram.com/p/CouFpdPIf_A/",
    price: 99.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Paco Rabanne 1 Million Lucky is a vibrant and daring fragrance with a mix of hazelnut, green plum, and cedarwood. It exudes a youthful and energetic vibe, making it a bold choice for a modern, confident man.",
  },
  {
    brand: "Prada",
    image: p38,
    productName: "Luna Rossa Ocean",
    link: "https://www.instagram.com/p/CouFpdPIf_A/",
    price: 109.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "A cologne for men augmented by the cutting-edge molecule of AmberXtreme, boosting the ingredients intensity and diffusing a powerful trail. Fragrance is intensified by the warmth of your own body.",
  },
  {
    brand: "Versace",
    image: p39,
    productName: "Eros Flame",
    link: "https://www.instagram.com/p/CouDKjyqwZW/",
    price: 114.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Eros Flame by Versace is a passionate and intense fragrance, combining notes of citrus, black pepper, and vanilla. It exudes a fiery and seductive aura, capturing the essence of a confident and vibrant individual.",
  },
  {
    brand: "Emporio Armani",
    image: p40,
    productName: "Stronger With You Absolutely",
    link: "https://www.instagram.com/p/CouC_6XIgrC/",
    price: 104.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "The fragrance opens with a new fresh and intoxicating facet: a subtle rum accord that blends with bergamot and elemi, a resin-like scent, to reveal a fresh, spicy and ambery aspect.",
  },
  {
    brand: "Versace",
    image: p41,
    productName: "Pour Homme Dylan Blue",
    link: "https://www.instagram.com/p/CouB3a3oFlD/",
    price: 104.99,
    size: 100,
    seasons: ["Summer", "Fall"],
    description:
      "Versace Pour Homme Dylan Blue is a modern and dynamic fragrance with notes of bergamot, grapefruit, and patchouli. It exudes a Mediterranean freshness and sophistication, capturing a contemporary and confident spirit.    ",
  },
];

for (let i = 0; i < productsData.length; i++) {
  productsData[i].id = i + 1;
}
function getProductData(id) {
  const productData = productsData.find((product) => product.id === id);
  if (productData === undefined) {
    console.log("Product not found with id: " + id);
    return {};
  }
  return productData;
}

export { productsData, getProductData };
